<template>
    <a-modal title="添加" v-model="visible" width="900px" :footer="null">
        <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
            >
                <a-form-model-item  label="行政区编号" prop="regionCode">
                <a-input v-model="form.regionCode"/>
                </a-form-model-item>
                <a-form-model-item label="行政区名称" prop="regionName">
                    <a-input
                    v-model="form.regionName"
                    />
                </a-form-model-item>
                <a-form-model-item label="编号简写" prop="shortForCode">
                    <a-input
                    v-model="form.shortForCode"
                    />
                </a-form-model-item>
                <a-form-model-item label="行政区等级" prop="regionType">
                    <a-radio-group v-model="form.regionType">
                        <a-radio :value="item.type" v-for="(item, index) in types" :key="index">
                        {{item.typeName}}
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="拼音缩写" prop="pinyin">
                    <a-input
                    v-model="form.pinyin"
                    />
                </a-form-model-item>
                <a-form-model-item label="行政区描述" prop="represent">
                    <a-input
                    v-model="form.represent"
                    />
                </a-form-model-item>
                <a-form-model-item label="上级行政编号"  prop="parentCode">
                  <a-input
                    v-model="form.parentCode"
                    />
                </a-form-model-item>
                <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="onSubmit">
                    提交
                </a-button>
                <a-button style="margin-left: 10px;" @click="resetForm">
                    清空
                </a-button>
                </a-form-model-item>
            </a-form-model>
    </a-modal>
</template>

<script>
import { queryRegionTypeApi, addRegionTypeApi } from '@/api/division'
export default {
    data() {
        return{
            types: [],
            visible: false,
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            other: '',
            form: {
                regionCode: '',
                regionName: '',
                parentCode: '',
                shortForCode: '',
                shortForName: '',
                regionType: '',
                pinyin: '',
                represent: '',
                portrait: ''
            },
            rules: {
                regionCode: [{ required: true, message: '行政区编号不能为空', trigger: 'blur' }],
                regionName: [{ required: true, message: '行政区名称不能为空', trigger: 'blur' }],
                parentCode: [{ required: true, message: '上级行政区编号不能为空', trigger: 'blur' }],
                shortForCode: [{ required: true, message: '编号简写不能为空', trigger: 'blur' }],
                shortForName: [{ required: true, message: '名称简写不能为空', trigger: 'blur' }],
                pinyin: [{ required: true, message: '拼音缩写不能为空', trigger: 'blur' }],
                represent: [{ required: true, message: '行政区描述不能为空', trigger: 'blur' }],
                regionType: [{ required: true, message: '选择行政区等级', trigger: 'change' },],
            },
        }
    },
    mounted() {
    },
    methods: {
        queryRegionType() {
            queryRegionTypeApi().then(res => {
                this.types = res.result
            })
        },
        onSubmit() {
            this.$refs.ruleForm.validate((valid, values) => {
                if (valid) {
                    addRegionTypeApi({...this.form}).then(res => {
                        if(res.code === '0') {
                            this.$message.success('新增成功')
                            this.visible = false
                            this.$emit('query')
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        resetForm() {
            this.$refs.ruleForm.resetFields();
        },
        showAdd() {
            this.visible = true
            this.queryRegionType()
        }
    },
}
</script>