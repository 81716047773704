<template>
    <div class="index">
        <a-form layout="inline">
            <a-form-item label="名称">
                <a-input placeholder="请输入行政区名称" v-model="params.regionName" allow-clear @change="onSearch"/>
            </a-form-item>
            <a-form-item> 
              <a-button type="primary" @click="onSearch">  查询 </a-button>
              <a-button type="primary" style="margin-left: 10px;" @click="fileUploadModal">  批量导入 </a-button>
              <!-- <a-button type="primary" style="margin-left: 10px;" @click="exportRegion">  批量导出 </a-button> -->
              <a-button type="primary" style="margin-left: 10px;" @click="deleteRegion">  批量删除 </a-button>
              <a-button type="primary" style="margin-left: 10px;" @click="addDivision">  新增 </a-button>
            </a-form-item>
        </a-form>
        <a-table size="middle" bordered :scroll="{  y: 580 }" :columns="columns" :data-source="tableData" style="margin-top: 20px;"  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :loading="loading" :pagination="pagination"  @change="handleTableChange">
            <span slot="regionCode" slot-scope="text, record" @click="copyQuick(record.regionCode)">{{record.regionCode}}</span>
            <template slot-scope="text, record" slot="regionName">
                <a-input
                v-if="record.editable"
                  style="margin: -5px 0;width:200px;"
                  :value="text"
                  @change="e => handleChangeInput(e.target.value, record)"
                />
                 <span v-if="!record.editable">{{text}}</span>
            </template>
            <template slot-scope="text, record" slot="regionType">
              {{REGION_TYPE[record.regionType]}}
            </template>
            <template slot-scope="text, record" slot="portrait">
              <div class="portrait-logo"><div class="logo-font">{{record.regionName[0]}}</div></div>
            </template>
             <template slot="action" slot-scope="text, record, index">
              <div class="editable-row-operations">
                <span v-if="record.editable">
                  <a @click="() => save(record)">保存</a>
                  <a-popconfirm title="确定取消?" @confirm="cancel(record)"  @cancel="closePop" style="margin-left: 20px">
                    <a>取消</a>
                  </a-popconfirm>
                </span>
                <span v-else>
                  <a :disabled="isEdit" @click="() => edit(record, index)">编辑</a>
                </span>
                <a @click="() => editArea(record)" style="margin-left: 10px;" v-if="record.regionType == 'street'">编辑地形图</a>
                 <a @click="() => drawArea(record)" style="margin-left: 10px;" v-if="record.regionType == 'street'">绘制地形图</a>
              </div>
            </template>
        </a-table>
         <a-modal
          title="上传行政区划数据"
          :visible="showModal"
          :confirm-loading="confirmLoading"
          @ok="handleOk"
          @cancel="handleCancel"
        >
            <a-upload
              name="file"
              action="https://www.zhongshihudong.com/garbage-collect-server/region/upload"
              @change="handleChange"
            >
              <a-button> <a-icon type="upload" />  选择文件</a-button>
            </a-upload>
        </a-modal>
        <add ref="add" @query="queryRegionList"></add>
    </div>
</template>

<script>
import add from './component/add.vue'
import mixin from '@/utils/mixin'
import { queryRegionList, exportRegionApi, updateRegionApi , deleteRegionApi, queryRegionTypeApi, addRegionTypeApi} from '@/api/division'
const REGION_TYPE = {
  'province': '省级',
  'city': '市级',
  'area': '区级',
  'street': '街道',
  'community': '社区',
  'unknown': '其他'
}
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    this.selectedRows = selectedRows
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
  {
    dataIndex: 'regionCode',
    key: 'regionCode',
    title: '行政区编号',
    scopedSlots: {customRender: 'regionCode'}
  },
  {
    title: '图标',
    dataIndex: 'portrait',
    key: 'portrait',
    scopedSlots: { customRender: 'portrait' },
  },
  {
    title: '行政区',
    dataIndex: 'regionName',
    key: 'regionName',
    width: '20%',
    scopedSlots: { customRender: 'regionName' },
  },
  {
    title: '行政级别',
    dataIndex: 'regionType',
    key: 'regionType',
    scopedSlots: { customRender: 'regionType' },
  },
  {
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  }
];
export default {
  components: { add },
  mixins: [mixin],
  data() {
    return {
      regions: [],
      REGION_TYPE: {
        'province': '省级',
        'city': '市级',
        'area': '区级',
        'street': '街道',
        'community': '社区',
        'unknown': '其他'
      },
      types: [],
      selectedRowKeys: [],
      tmpTarget: {},
      confirmLoading: false,
      showModal: false,
      isEdit: false,
      params: {
        regionName: ''
      },
      pagination: {
            pageSize: 15,
            current: 1,
            total: 0,
            onChange: (current) => this.changePage(current)
        },
       loading: false,
       rowSelection,
       options: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          children: [
            {
              value: 'hangzhou',
              label: 'Hangzhou',
              children: [
                {
                  value: 'xihu',
                  label: 'West Lake',
                },
              ],
            },
          ],
        },
       ],
      divisions: ['苏州市', '吴中区'],
      value: [],
      tableData: [],
      columns,
      list: []
    };
  },
  mounted() {
    this.$getRegionNodeChild().then(res => {
      this.regions = res
    })
    this.queryRegionList()
    this.queryRegionType()
  },
  methods: {
    editArea(record){
       this.$router.push({path: '/map/drawPolyline', query: {...record}})
    },
    drawArea(record) {
       this.$router.push({path: '/map/drawPolyline2', query: {...record}})
    },
    addDivision() {
      this.$refs.add.showAdd()
    },
    onSearch(e) {
      this.pagination.current = 1
      this.pagination.total = 0
      this.queryRegionList()
    
    },
    handleChangeRegionType(e) {
      console.log(e)
    },
    queryRegionType() {
      queryRegionTypeApi().then(res => {
        this.types = res.result
      })
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleChangeInput(value, record) {
      const newData = [...this.tableData];
      const target = newData.filter(item => record.regionName === item.regionName)[0];
      if (target) {
        target.regionName = value;
        // this.tableData = newData;
      }
      this.tmpTarget = target


    },
    handleChange(info) {
      console.log(info)
      if (info.file.status === 'done') {
        this.$message.success('上传成功');
      } else if (info.file.status === 'error') {
        this.$message.error('上传失败');
      }
    },
    handleOk() {
      this.showModal = false
    },
    handleCancel() {
      this.showModal = false
    },
    fileUploadModal() {
      this.showModal = true
    },
    exportRegion() {
      exportRegionApi().then(res => {
        var blob = new Blob([res], {type: 'application/vnd.ms-excel'})
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '行政区划列表.xlsx'
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      })
    },
    save() {
      this.isEdit = false
      updateRegionApi({...this.tmpTarget}).then(res => {
        this.queryRegionList()
        this.$message.success('修改成功')
      }).catch(() => {
         this.$message.eroor('修改失败')
      })
    },
    cancel() {
      this.isEdit = false
      this.queryRegionList()
    },
    closePop() {

    },
    deleteRegion() {
      let ids = []
      this.selectedRowKeys.forEach(item => {
        ids.push(this.tableData[item].id)
      })
      deleteRegionApi(ids).then(res => {
        this.$message.success('删除成功')
        this.queryRegionList()
        this.selectedRowKeys = []
      })
      .catch(() => {
        this.$message.error('删除失败')
      })
    },
    edit(record, index) {
      const newData = [...this.tableData];
      const target = newData.filter(item => record.regionCode === item.regionCode)[0];
      target.editable = true
      this.tableData = newData
      this.isEdit = true
      console.log(this.tableData[index])
      this.tmpTarget = target

    },
    changePage(current) {
        this.pagination.current = current
        this.queryRegionList()
    },
    handleTableChange(pagination, filters, sorter) {
    },
    queryRegionList() {
      this.loading = true
      this.params.page = this.pagination.current
      this.params.limit = this.pagination.pageSize
      this.isEdit = false
      queryRegionList({...this.params}).then(res => {
          this.tableData = res.result.content
          this.tableData.forEach((item, index) => {
            item.editable = false
          })
          this.loading = false
          this.pagination.current = res.result.number + 1
          this.pagination.total = res.result.totalElements
      })
    }
  }
};
</script>

<style scoped>
.index{
    /* background: #F5F7F9; */
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
}
.ant-form{
    text-align: initial;
}
.portrait-logo{
  width: 40px;
  height: 40px;
  background: #1890ff;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


</style>